import React from "react";

export default function AboutSection({ className }) {
  return (
    <section className={`about-three py-120 rpy-100 ${className || ""}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-three-image rmb-60 mr-10 rmr-0 wow fadeInLeft delay-0-2s">
              <img
                src={
                  require(`../../assets/images/about/man-typing.jpg`).default
                }
                alt="About"
              />
              <img
                src={
                  require(`../../assets/images/about/show-me.png`).default
                }
                alt="About"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content pl-70 rpl-0 wow fadeInRight delay-0-2s">
              <div className="section-title mb-35">
                <span className="sub-title">It Support For Business</span>
                <h2>
                  Preparing for your success, Trusted source in IT services.
                </h2>
              </div>
              <p className="text-justify">
                As the world becomes increasingly reliant on technology,
                businesses need a reliable and experienced partner to help them
                navigate the ever-changing landscape of IT services. At our tech
                agency company, we understand the importance of staying ahead of
                the curve and preparing for our clients' success. With a team of
                experts in various fields of technology, we offer a wide range
                of services tailored to meet the unique needs of each business
                we work with. From cybersecurity and data management to software
                development and cloud computing, we are committed to providing
                cutting-edge solutions that empower our clients to achieve their
                goals. As a trusted source in IT services, we prioritize
                transparency, communication, and collaboration to build lasting
                partnerships with our clients. Whether you're a small start-up
                or a large corporation, we have the expertise and resources to
                help you succeed in today's digital landscape.
              </p>
              {/* <ul className="list-style-three mt-15">
                <li>which means as the company ramps</li>
                <li>Available in 14.1-in. and 16-in. versions.</li>
                <li>The potential loss of the Touch Bar.</li>
                <li>High-speed USB 4.0.aaaa</li>
              </ul>
              <a href="/about-one" className="theme-btn style-three mt-25">
                View details
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
