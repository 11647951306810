import React from "react";
import CounterUp from "../helpers/CounterUp";
import SliderCom from "../helpers/SliderCom";

export default function TestimonialSection() {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section
      className="testimonial-section bg-lighter pt-25 rpt-95 pb-120 rpb-100"
      id="testimonial-section"
    >
      <div className="container">
        <div className="section-title text-center mb-65">
          <h2>
            Success Stories <br />
          </h2>
        </div>
        <div className="fact-counter-color text-center mb-30">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="success-item wow fadeInUp delay-0-2s">
                <span className="count-text plus">
                  <CounterUp sectionSelect="testimonial-section" endValue={3} />
                </span>
                <p>Awards Won</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="success-item wow fadeInUp delay-0-4s">
                <span className="count-text plus">
                  <CounterUp sectionSelect="testimonial-section" endValue={4} />
                </span>
                <p>Years Experience</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="success-item wow fadeInUp delay-0-6s">
                <span className="count-text plus">
                  <CounterUp sectionSelect="testimonial-section" endValue={7} />
                </span>
                <p>Happy Clients</p>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonial-wrap">
          <SliderCom settings={settings}>
            <div className="testimonial-item wow fadeInRight delay-0-2s">
              <div className="author-description">
                <img
                  src={
                    require(`../../assets/images/testimonials/samuel.jpg`)
                      .default
                  }
                  alt="Author"
                />
                <div className="designation">
                  <h5>Samuel Sonowo</h5>
                  <span>Design Lead, Alpha Training Lab</span>
                </div>
                <i className="fas fa-quote-right"></i>
              </div>
              <p className="text-justify">
                As a nonprofit organization, we needed a digital agency that
                understood our unique needs. Autonomous Tech stepped in and
                exceeded our expectations. They developed a beautiful platform
                that effectively communicates our mission and engages our users.
                Their social media campaigns have helped us reach a wider
                audience and generate more outreach. The team at Autonomous Tech
                is not only skilled but also genuinely passionate about making a
                difference. They have played a crucial role in our growth and
                success, and we are immensely grateful for their partnership.
              </p>
            </div>
            <div className="testimonial-item">
              <div className="author-description">
                <img
                  src={
                    require(`../../assets/images/testimonials/val.jpg`).default
                  }
                  alt="Author"
                />
                <div className="designation">
                  <h5>Valentine Aninyem</h5>
                  <span>CEO BitsCard</span>
                </div>
                <i className="fas fa-quote-right"></i>
              </div>
              <p className="text-justify">
                Autonomous Tech has been an invaluable partner for our tech
                startup. Their team of experts crafted a brilliant blockchain
                solutions that perfectly aligned with our goals. From App
                implementation to SEO, they executed every aspect flawlessly.
                The result? Our App now ranks at the top of search engine
                results, and we've seen a significant increase in leads. The
                team at Autonomous Tech is professional, responsive, and highly
                skilled. We are thrilled with the outcomes and continue to work
                with them for ongoing success.
              </p>
            </div>
            <div className="testimonial-item wow fadeInLeft delay-0-2s">
              <div className="author-description">
                <img
                  src={
                    require(`../../assets/images/testimonials/shams.jpg`)
                      .default
                  }
                  alt="Author"
                />
                <div className="designation">
                  <h5>Shamsudeen A. Aderoju</h5>
                  <span>Programme Coordinator, TiideLab</span>
                </div>
                <i className="fas fa-quote-right"></i>
              </div>
              <p className="text-justify">
                At Tiidelab, we are constantly seeking innovative solutions to
                empower the youth and drive economic growth. When it came to
                enhancing our digital presence, we turned to Autonomous Tech,
                and they exceeded our expectations in every way. From our
                initial consultation, it was clear that Autonomous Tech
                understood our mission and the unique challenges we faced as a
                youth support organization. They conducted in-depth research and
                developed a comprehensive digital strategy that aligned
                perfectly with our objectives.
              </p>
            </div>
            <div className="testimonial-item">
              <div className="author-description">
                <img
                  src={
                    require(`../../assets/images/testimonials/yusuf.jpg`)
                      .default
                  }
                  alt="Author"
                />
                <div className="designation">
                  <h5>Yusuf B. Jibril</h5>
                  <span>CEO Bajis Global</span>
                </div>
                <i className="fas fa-quote-right"></i>
              </div>
              <p className="text-justify">
                What impressed me the most about Autonomous Tech was their
                dedication to my success. They continually monitored the
                performance of my digital campaigns, analyzed data, and provided
                valuable insights to optimize results further. Their proactive
                approach ensured that we stayed ahead of the competition and
                adapted to the ever-changing digital landscape. Thanks to the
                exceptional work of Autonomous Tech, my travel agency has
                experienced tremendous growth. My online visibility has soared,
                my bookings have increased, and my brand is now recognized as a
                trusted authority in the travel industry.
              </p>
            </div>
          </SliderCom>
        </div>
      </div>
    </section>
  );
}
