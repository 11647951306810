import React, { useEffect } from "react";
import logo2 from "../../../../assets/images/logos/logo-two.png";
// import logo from "../../../../assets/images/logos/logo.png";
import logo from "../../../../assets/images/logos/logo-dark-notext_3_100x50.png";
import StickyMenu from "../../../../lib/StickyMenu";
import Navigation from "../../../helpers/Navigation";
import MobileHeaderCom from "../../Mobile/MobileHeaderCom";

function HeaderHomeOne() {
  useEffect(() => {
    StickyMenu(".main-header");
  });

  return (
    <>
      <MobileHeaderCom logo={logo2} />
      <header className="main-header header-one">
        <div className="header-top bg-lighter py-10">
          <div className="top-left">
            <ul>
              <li>
                Call: <a href="callto:+234-7067288453">07067288453</a>
              </li>
              <li>
                Email:{" "}
                <a href="mailto:demo@example.com">autonomoustechng@gmail.com</a>
              </li>
              <li>Our Address: Penthouse, Lubge, Abuja</li>
            </ul>
          </div>
          <div className="top-right">
            {/* <div className="office-time">
              <i className="far fa-clock"></i>
              <span>08:00 am - 06:00 pm</span>
            </div> */}
            <div className="social-style-one">
              <a href="http://facebook.com/autonomoustech">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="http://twitter.com/autonomoustech">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://www.instagram.com/autonomoustech/">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://www.pinterest.com/autonomoustech/">
                <i className="fab fa-pinterest-p"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="header-upper bg-white">
          <div className="container-fluid clearfix">
            <div className="header-inner d-flex align-items-center">
              <div className="logo-outer  py-15 px-85">
                <div className="logo">
                  <a href="/">
                    <img src={logo} alt="Logo" title="Logo" />
                  </a>
                </div>
              </div>

              <div className="nav-outer clearfix d-flex align-items-center">
                <Navigation className="nav-home-one" />
                <div className="menu-btn">
                  <a href="/contact" className="theme-btn">
                    meet with us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default HeaderHomeOne;
