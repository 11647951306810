import React from "react";

function ServiceSection() {
  return (
    <section className="services-section pt-120 rpt-100 pb-90 rpb-70">
      <div className="container">
      <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-10">
            <div className="section-title text-center mb-35">
              <span className="sub-title">WHO WE ARE</span>
              <h2>We deal with the aspects of professional IT Services</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-2s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-computer"></i>
                </div>
                <h6>IT Management</h6>
                <p className="text-justify">
                  We offer comprehensive IT management services that help
                  businesses leverage technology to improve their operations and
                  achieve their goals.
                </p>
                <a className="btn-circle" href="/service-details">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                {/* <h3>Preparing For Your Business Success With IT Solution</h3> */}
                <p className="text-justify">
                  Our team of experienced IT professionals will work closely
                  with you to assess your needs, identify areas for improvement,
                  and implement solutions that enhance your productivity and
                  security.
                </p>
                <a href="/service-details" className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-4s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-web-development-4"></i>
                </div>
                <h6>Web Development</h6>
                <p className="text-justify">
                  Our web design services are designed to help businesses create
                  a strong online presence and attract more customers with lots
                  of outreach
                </p>
                <a className="btn-circle" href="/service-details">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                {/* <h3>Preparing For Your Business Success With IT Solution</h3> */}
                <p className="text-justify">
                  We work closely with you to understand your brand and target
                  audience, and create a website that is visually appealing,
                  user-friendly, and optimized for search engines.
                  {/* Our services include website design and development, 
                  website maintenance, and e-commerce solutions. */}
                </p>
                <a href="/service-details" className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-6s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-web"></i>
                </div>
                <h6>Cloud Services</h6>
                <p className="text-justify">
                  Our cloud services are designed to help businesses migrate to
                  the cloud and take advantage of the many benefits it offers
                  with high conveniences
                </p>
                <a className="btn-circle" href="/service-details">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                {/* <h3>Preparing For Your Business Success With IT Solution</h3> */}
                <p className="text-justify">
                  We work closely with you to assess your needs and identify the
                  best cloud solutions for your business. Our services include
                  cloud migration, cloud infrastructure management, and cloud
                  security.
                </p>
                <a href="/service-details" className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-8s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-data"></i>
                </div>
                <h6>Blockchain </h6>
                <p className="text-justify">
                  We believe that blockchain technology has the potential to
                  revolutionize many industries. Our blockchain services are
                  designed to help explore and implement
                </p>
                <a className="btn-circle" href="/service-details">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                {/* <h3>Preparing For Your Business Success With IT Solution</h3> */}
                <p className="text-justify">
                  solutions that enhance their operations and improve their
                  security. Our services include blockchain consulting, smart
                  contract development, and blockchain-based application
                  development.
                </p>
                <a href="/service-details" className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceSection;
