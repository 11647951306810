import React from "react";
import background from "../../assets/images/contact/contact-image.jpg";
import Layouts from "../helpers/Layouts";

export default function Contact() {
  return (
    <>
      <Layouts
        pageTitle="Contact us"
        // breadcrumbs={[
        //   { name: "home", path: "/" },
        //   { name: "contact", path: "/contact" },
        // ]}
      >
        <section className="contact-page py-120 rpy-100">
          <div className="container">
            <div className="contact-info-area mb-80">
              <div className="contact-info-item wow fadeInUp delay-0-2s">
                <i className="far fa-map"></i>
                <p>Penthouse, Lubge, Abuja</p>
              </div>
              <div className="contact-info-item wow fadeInUp delay-0-4s">
                <i className="far fa-envelope"></i>
                <p>
                  <a href="mailto:autonomoustechng@gmail.com">
                    autonomoustechng@gmail.com
                  </a>{" "}
                  <br />
                  <a href="/" target="_blank">
                    www.atechng.com
                  </a>
                </p>
              </div>
              <div className="contact-info-item wow fadeInUp delay-0-6s">
                <i className="fas fa-phone-alt"></i>
                <p>
                  <a href="callto:+2347067288453">+2347067288453</a> <br />
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="contact-form-left bgs-cover h-100 wow fadeInLeft delay-0-2s"
                  style={{ backgroundImage: `url(${background})` }}
                >
                  <h2>Leave A Message</h2>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="contact-form ml-40 rml-0 rmt-55 wow fadeInRight delay-0-2s">
                  <h3 className="comment-title mb-35">Send A Message</h3>
                  <form
                    id="contact-form"
                    className="contact-form"
                    name="contact"
                    action="/contact"
                    method="post"
                    data-netlify="true"
                    onSubmit="submit"
                  >
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="name">Your name</label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder="Name"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="email">Your email address</label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="number">Your phone number</label>
                          <input
                            type="text"
                            id="number"
                            name="number"
                            className="form-control"
                            placeholder="Phone Number"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="service">Service Required</label>
                          <select id="service" name="service">
                            <option value="Service" selected="">
                              Service
                            </option>
                            <option value="About">About</option>
                            <option value="Team">Team</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <textarea
                            name="comments"
                            id="comments"
                            className="form-control"
                            rows="4"
                            placeholder="Let us know what you need."
                            required=""
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <button type="submit" className="theme-btn">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="contact-page-map">
          <div className="our-location">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12786.181752351351!2d7.374611576491797!3d8.963303114304031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e73ad277e434b%3A0x720c5932449bef2!2sB%20193%2C%20Fela%20Anikulapo-Kuti%20Street%2C%20Penthouse%20Estate!5e0!3m2!1sen!2sng!4v1684567456123!5m2!1sen!2sng"
              height="650"
              style={{ border: "0", width: "100%" }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </Layouts>
    </>
  );
}
